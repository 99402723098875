import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import './../css/cloudCash.css';

import ContactForm from './../components/contactForm.jsx';

const FeatureSection = ({ title, description, svgComponent, reverse }) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	return (
		<section className={`py-5 ${reverse ? 'bg-light' : ''}`}>
			<Container>
				<Row 
					ref={ref}
					className={`align-items-center ${reverse ? 'flex-row-reverse' : ''}`}
				>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						{svgComponent}
					</Col>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						<h3 className="text-primary mb-4">{title}</h3>
						<p><span>{description}</span></p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

const ReportIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="reportGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#reportGradient)"
			strokeWidth="2"
			d="M10 10h44v44H10z"
			strokeDasharray="250"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="250"
				to="0"
				dur="1.2s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#fff"
			stroke="#0d6efd"
			strokeWidth="2"
			d="M18 18h28v28H18z"
			strokeDasharray="150"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="150"
				to="0"
				dur="0.8s"
				begin="0.5s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#4dabf7"
			d="M28 28h8v12h-8z"
		>
			<animate
				attributeName="opacity"
				values="0;1;0"
				dur="2s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const CloudIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="cloudGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#cloudGradient)"
			strokeWidth="2"
			d="M46 40H18a12 12 0 110-24h2a16 16 0 0132 0 10 10 0 01-6 18"
			strokeDasharray="300"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="300"
				to="0"
				dur="1.5s"
				fill="freeze"
			/>
		</path>
		<circle
			cx="32"
			cy="32"
			r="4"
			fill="#4dabf7"
		>
			<animate
				attributeName="r"
				values="4;6;4"
				dur="2s"
				repeatCount="indefinite"
			/>
		</circle>
	</svg>
);

const ComplianceIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="complianceGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#complianceGradient)"
			strokeWidth="2"
			d="M22 52l-12-12 6-6 6 6 24-24 6 6-30 30z"
			strokeDasharray="200"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="200"
				to="0"
				dur="1s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#4dabf7"
			d="M32 16l8 8-8 8-8-8 8-8z"
		>
			<animate
				attributeName="transform"
				attributeType="XML"
				type="rotate"
				from="0 32 32"
				to="360 32 32"
				dur="4s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const CloudCashPage = () => {
	const features = [
		{
			title: 'Автоматическая отчетность',
			description: 'Формирование и отправка электронных чеков в ФНС в соответствии с ФЗ-54',
			svg: <ReportIcon />,
		},
		{
			title: 'Работа из любой точки',
			description: 'Доступ к кассе через веб-интерфейс с любого устройства 24/7',
			svg: <CloudIcon />,
			reverse: true,
		},
		{
			title: 'Полное соответствие 54-ФЗ',
			description: 'Гарантия легальности операций и защита от штрафов',
			svg: <ComplianceIcon />,
		},
	];

	return (
		<Container fluid className="main-container">
			<header className="text-center py-5">
				<h1 className="display-4 text-primary">Облачные кассы</h1>
				<p className="mt-3"><span>Готовое решение для расчётов с фискальным чеком</span></p>
			</header>

			{features.map((feature, index) => (
				<FeatureSection
					key={index}
					title={feature.title}
					description={feature.description}
					svgComponent={feature.svg}
					reverse={index % 2 !== 0}
				/>
			))}
			<ContactForm/>
		</Container>
	);
};

export default CloudCashPage;