import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import './../css/onlinePayments.css';

import ContactForm from './../components/contactForm.jsx';

const FeatureSection = ({ title, description, svgComponent, reverse }) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	return (
		<section className={`py-5 ${reverse ? 'bg-light' : ''}`}>
			<Container>
				<Row 
					ref={ref}
					className={`align-items-center ${reverse ? 'flex-row-reverse' : ''}`}
				>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						{svgComponent}
					</Col>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						<h3 className="text-primary mb-4">{title}</h3>
						<p><span>{description}</span></p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

const SpeedPaymentIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="speedGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#speedGradient)"
			strokeWidth="2"
			d="M10 32h44M32 10v44"
			strokeDasharray="150"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="150"
				to="0"
				dur="0.8s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#4dabf7"
			d="M28 28l8-8 8 8-8 8-8-8z"
		>
			<animate
				attributeName="opacity"
				values="0;1;0"
				dur="2s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const SecurePaymentIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="secureGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#secureGradient)"
			strokeWidth="2"
			d="M32 58C16 50 8 38 8 28V8l24-4 24 4v20c0 10-8 22-24 30z"
			strokeDasharray="250"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="250"
				to="0"
				dur="1.5s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#4dabf7"
			d="M32 38a6 6 0 100-12 6 6 0 000 12z"
		>
			<animate
				attributeName="fill-opacity"
				values="0;0.8;0"
				dur="2.5s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const GlobalPaymentIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="globalGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<circle
			cx="32"
			cy="32"
			r="24"
			fill="none"
			stroke="url(#globalGradient)"
			strokeWidth="2"
			strokeDasharray="200"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="200"
				to="0"
				dur="1.2s"
				fill="freeze"
			/>
		</circle>
		<path
			fill="#4dabf7"
			d="M32 16l8 16-8 16-8-16 8-16z"
		>
			<animate
				attributeName="transform"
				attributeType="XML"
				type="rotate"
				from="0 32 32"
				to="360 32 32"
				dur="4s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const OnlinePaymentsPage = () => {
	const features = [
		{
			title: 'Мгновенные транзакции',
			description: 'Обработка платежей за 0.5-2 секунды с гарантией доставки средств',
			svg: <SpeedPaymentIcon />,
		},
		{
			title: 'Безопасность операций',
			description: 'Защита данных по стандарту PCI DSS с 256-битным шифрованием',
			svg: <SecurePaymentIcon />,
			reverse: true,
		},
		{
			title: 'Мультивалюта',
			description: 'Поддержка валют и всех популярных платежных систем',
			svg: <GlobalPaymentIcon />,
		},
	];

	return (
		<Container fluid className="main-container">
			<header className="text-center py-5">
				<h1 className="display-4 text-primary">Online платежи</h1>
				<p className="mt-3"><span>Надежный партнер в приеме online платежей</span></p>
			</header>

			{features.map((feature, index) => (
				<FeatureSection
					key={index}
					title={feature.title}
					description={feature.description}
					svgComponent={feature.svg}
					reverse={index % 2 !== 0}
				/>
			))}
			<ContactForm/>
		</Container>
	);
};

export default OnlinePaymentsPage;