import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import './../css/holdBenefits.css';

import ContactForm from './../components/contactForm.jsx';

const FeatureSection = ({ title, description, svgComponent, reverse }) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	return (
		<section className={`py-5 ${reverse ? 'bg-light' : ''}`}>
			<Container>
				<Row 
					ref={ref}
					className={`align-items-center ${reverse ? 'flex-row-reverse' : ''}`}
				>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						{svgComponent}
					</Col>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						<h3 className="text-primary mb-4">{title}</h3>
						<p><span>{description}</span></p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

const ShieldIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<path
			fill="none"
			stroke="#0d6efd"
			strokeWidth="2"
			d="M32 62.5c-16-7.958-22-20-22-30V5l22-3 22 3v27.5c0 10-6 22.042-22 30z"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="200"
				to="0"
				dur="1.5s"
				begin="0s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#4dabf7"
			d="M32 46.5c-1.105 0-2-.895-2-2v-10c0-1.105.895-2 2-2s2 .895 2 2v10c0 1.105-.895 2-2 2z"
		>
			<animate
				attributeName="opacity"
				values="0;1;0"
				dur="2s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const GraphIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="graphGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		<path
			fill="none"
			stroke="url(#graphGradient)"
			strokeWidth="2"
			strokeLinecap="round"
			strokeDasharray="200"
			d="M5 45l12-15 10 8 15-20 17 12"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="200"
				to="0"
				dur="1.2s"
				begin="0s"
				fill="freeze"
			/>
		</path>
		
		<circle cx="5" cy="45" r="3" fill="#4dabf7">
			<animate
				attributeName="fill"
				values="#4dabf7;#0d6efd;#4dabf7"
				dur="2s"
				repeatCount="indefinite"
			/>
		</circle>
	</svg>
);

const ClockIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="clockGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<circle
			cx="32"
			cy="32"
			r="28"
			fill="none"
			stroke="url(#clockGradient)"
			strokeWidth="2"
			strokeDasharray="200"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="200"
				to="0"
				dur="1s"
				begin="0s"
				fill="freeze"
			/>
		</circle>
		
		<path
			fill="#fff"
			stroke="#0d6efd"
			strokeWidth="2"
			strokeLinecap="round"
			d="M32 16v16l8 8"
		>
			<animate
				attributeName="opacity"
				from="0"
				to="1"
				dur="0.5s"
				begin="0.8s"
				fill="freeze"
			/>
		</path>
	</svg>
);

const RiskIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="riskGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#riskGradient)"
			strokeWidth="2"
			d="M32 12L12 52h40L32 12z"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="150"
				to="0"
				dur="1s"
				begin="0s"
				fill="freeze"
			/>
		</path>
		
		<path
			fill="#4dabf7"
			d="M32 38a2 2 0 01-2-2v-8a2 2 0 114 0v8a2 2 0 01-2 2z"
		>
			<animate
				attributeName="opacity"
				values="0;1;0"
				dur="2s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const HoldBenefitsPage = () => {
	const features = [
		{
			title: 'Защита от мошенничества',
			description: 'Обеспечиваем безопасность транзакций с помощью многоуровневой проверки',
			svg: <ShieldIcon />,
		},
		{
			title: 'Анализ платежей',
			description: 'Детальная аналитика финансовых потоков в реальном времени с графическими отчетами',
			svg: <GraphIcon />,
		},
		{
			title: 'Экономия времени',
			description: 'Автоматизация рутинных операций сокращает обработку платежей на 65%',
			svg: <ClockIcon />,
		},
		{
			title: 'Контроль рисков',
			description: 'Динамическая оценка рисков для каждой транзакции с машинным обучением',
			svg: <RiskIcon />, // Дополнительная иконка
		}
		// Добавить остальные преимущества
	];

	return (
		<Container fluid className="main-container">
			<header className="text-center py-5">
				<h1 className="display-4 text-primary">Преимущества холдирования платежей</h1>
				<p className="mt-3"><span>Надежная защита ваших финансовых операций</span></p>
			</header>

			{features.map((feature, index) => (
				<FeatureSection
					key={index}
					title={feature.title}
					description={feature.description}
					svgComponent={feature.svg}
					reverse={index % 2 !== 0}
				/>
			))}
			<ContactForm/>
		</Container>
	);
};

export default HoldBenefitsPage;