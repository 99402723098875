import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function LawInfo() {
	return <React.Fragment>
		<Container>
			<Row className="pt-5 d-none d-sm-block">
			</Row>
			<Row>
				<Col sm={12}>
					<h1>Правовая информация</h1>
					<p>Настоящий сайт предоставляет общие сведения информационного характера и не является публичной офертой в соответствии со статьей 437 Гражданского кодекса Российской Федерации.</p>
					<p>Все материалы и публикации на сайте защищены законодательством об авторском праве (статья 1259 ГК РФ). Копирование, воспроизведение или распространение контента без письменного разрешения правообладателя запрещено.</p>
					<p>Администрация сайта не несет ответственности за достоверность информации, размещенной третьими лицами, а также за возможный ущерб, причиненный в результате использования опубликованных материалов.</p>
					<p>Обработка персональных данных пользователей осуществляется в соответствии с Федеральным законом №152-ФЗ "О персональных данных". Подробные условия сбора и использования информации изложены в Политике конфиденциальности.</p>
					<p>Сайт может содержать ссылки на внешние интернет-ресурсы. Администрация не контролирует и не отвечает за содержание сторонних веб-сайтов.</p>
					<p>Все споры и разногласия подлежат разрешению в соответствии с законодательством Российской Федерации по месту официальной регистрации владельца сайта.</p>
					<p>Администрация оставляет за собой право вносить изменения в содержание сайта и условия настоящего уведомления без предварительного уведомления.</p>
				</Col>
			</Row>
			<Row className="pb-5 d-none d-sm-block">
			</Row>
		</Container>
	</React.Fragment>;
}

export default LawInfo;