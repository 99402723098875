import {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './../logo.png';

function Header() {
	const [expanded, setExpanded] = useState(false);
	return <header>
		{/*<Navbar expand="lg" className="bg-body-tertiary">*/}
		<Navbar expanded={expanded} expand="lg" className="bgcab" fixed="top">
			<Container>
				<Navbar.Brand href="/">
					<img src={logo} className="logo" alt="logo" />
				</Navbar.Brand>
				<Navbar.Toggle
					onClick={() => setExpanded(expanded ? false : 'expanded')}
					aria-controls="basic-navbar-nav"
				/>
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link href="/" onClick={() => setExpanded(false)}>Главная</Nav.Link>
						<NavDropdown title="Агрегаторам" id="basic-nav-dropdown">
							<NavDropdown.Item href="/pay_box">Терминалы</NavDropdown.Item>
							<NavDropdown.Item href="/white_label">White Label</NavDropdown.Item>
							<NavDropdown.Item href="/hold_benefits">Холдирование</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="Для бизнеса" id="basic-nav-dropdown">
							<NavDropdown.Item href="/online_payments">Онлайн платежи</NavDropdown.Item>
							<NavDropdown.Item href="/cloud_cash">Облачные кассы</NavDropdown.Item>
							<NavDropdown.Item href="/risk_control">Безопасность</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="Прочее" id="basic-nav-dropdown">
							<NavDropdown.Item href="/privacy_policy">Политика конфиденциальности</NavDropdown.Item>
							<NavDropdown.Item href="/law_info">Правовая информация</NavDropdown.Item>
							{/*<NavDropdown.Divider />
							<NavDropdown.Item href="#action/3.4">Поддержка</NavDropdown.Item>*/}
						</NavDropdown>
					</Nav>
					<Nav>
						{/*<Nav.Link href="#connect" onClick={() => setExpanded(false)}>Поддержка</Nav.Link>*/}
						<Nav.Link href="mailto:support@paytoway.ru">Поддержка</Nav.Link>
						<Nav.Link href="https://lk.paytoway.ru">Личный кабинет</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	</header>;
}

export default Header;