import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
import Button from 'react-bootstrap-button-loader';

// import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import ContactForm from './../components/contactForm.jsx';

class Business extends Component {
	constructor(props){
		super(props);

		// this.inputOrg = React.createRef();
		// this.inputCity = React.createRef();
		// this.inputStreet = React.createRef();
		// this.inputHouse = React.createRef();

		this.state = {
			loading: false
		};

		this.counterStreet = 0;
		this.timeoutStreet = null;
		this.counterHouse = 0;
		this.timeoutHouse = null;

		this.sendForm = this.sendForm.bind(this);
	}

	sendForm() {
		this.setState({loading:!this.state.loading});
		setTimeout(()=>{
			this.setState({loading:!this.state.loading});
		},1000);
	}

	render(){
		return(<React.Fragment><div className="">
			<div className="bgcab">
				<Container>
					<Row className="pt-5 d-none d-sm-block">
					</Row>
					<Row>
						<Col sm={12} md={6} className="business-r-t">
							<div className="business-r-t-i">
								<b>Управляйте рисками</b><br/>
								<p><span>С нашей платформой безопасность не является дополнительным компонентом. Она разработана так, чтобы помочь вам отслеживать операции и позволить сосредоточиться на росте компании. Вы сможете воспользоваться нашим глобальным опытом и системой мониторинга мошенничества, которая позволит постоянно узнавать о новых схемах</span></p>
							</div>
						</Col>
						<Col sm={12} md={6}>
							<img src={require('./../images/risk.png')} style={{width:'100%',padding: '10px 0'}} />
						</Col>
					</Row>
					<Row className="pb-5 d-none d-sm-block">
					</Row>
				</Container>
			</div>
			<ContactForm/>
		</div></React.Fragment>);
	}
}

export default Business;