import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";

import Header from './components/header.jsx';
import Footer from './components/footer.jsx';
import Business from './pages/business.jsx';
import LawInfo from './pages/lawInfo.jsx';
import PrivacyPolicy from './pages/privacyPolicy.jsx';
import PayBox from './pages/payBox.jsx';
import WhiteLabel from './pages/whiteLabel.jsx';
import HoldBenefits from './pages/holdBenefits.jsx';
import OnlinePayments from './pages/onlinePayments.jsx';
import CloudCash from './pages/cloudCash.jsx';
import RiskControl from './pages/riskControl.jsx';

function App() {
	return (<Router>
		<Header/>
		<Routes>
			<Route exact path='/law_info' element={<LawInfo/>}/>
			<Route exact path='/privacy_policy' element={<PrivacyPolicy/>}/>
			<Route exact path='/pay_box' element={<PayBox/>}/>
			<Route exact path='/white_label' element={<WhiteLabel/>}/>
			<Route exact path='/hold_benefits' element={<HoldBenefits/>}/>
			<Route exact path='/online_payments' element={<OnlinePayments/>}/>
			<Route exact path='/cloud_cash' element={<CloudCash/>}/>
			<Route exact path='/risk_control' element={<RiskControl/>}/>
			<Route exact path='/' element={<Business/>}/>
		</Routes>
		<Footer/>
	</Router>);
}

export default App;
