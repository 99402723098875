import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function PrivacyPolicy() {
	return <React.Fragment>
		<Container>
			<Row className="pt-5 d-none d-sm-block">
			</Row>
			<Row>
				<Col sm={12}>
					<h1>Политика конфиденциальности</h1>
					<p>Настоящая Политика конфиденциальности регулирует порядок сбора, использования и защиты персональных данных пользователей на информационном сайте PayToWay (далее — «Сайт»). Используя Сайт, вы соглашаетесь с условиями данной политики.</p>
					<hr/>
					<h2>1. Собираемые данные</h2>
					<p>Мы можем получать следующую информацию:</p>
					<p>- <b>Персональные данные</b>: имя, email-адрес и другие сведения, которые вы добровольно предоставляете через формы подписки, комментариев или обратной связи.</p>
					<p>- <b>Автоматические данные</b>: IP-адрес, тип браузера, операционная система, данные о посещенных страницах, время и дата доступа (через cookies и логи сервера).</p>
					<hr/>
					<h2>2. Цели использования данных</h2>
					<p>Информация используется для:</p>
					<p>- Обеспечения работы и улучшения функционала Сайта.</p>
					<p>- Обратной связи с пользователями (ответы на запросы, рассылка новостей).</p>
					<p>- Аналитики посещаемости и персонализации контента.</p>
					<p>- Защиты от мошеннических действий и соблюдения законов.</p>
					<hr/>
					<h2>3. Передача данных</h2>
					<p>Мы не передаем ваши персональные данные третьим лицам, за исключением:</p>
					<p>- Случаев, предусмотренных законодательством (по запросу суда или государственных органов).</p>
					<p>- Сервисных провайдеров (например, хостинг, аналитические инструменты), которые обрабатывают данные по нашему поручению и в соответствии с нашей политикой.</p>
					<hr/>
					<h2>4. Хранение и защита</h2>
					<p>- Данные хранятся до достижения целей их сбора или до вашего запроса на удаление.</p>
					<p>- Мы применяем технические (шифрование, защищенные протоколы) и организационные меры для предотвращения утечек и несанкционированного доступа.</p>
					<hr/>
					<h2>5. Ваши права</h2>
					<p>Вы можете:</p>
					<p>- Запросить доступ, исправление или удаление ваших данных.</p>
					<p>- Отозвать согласие на обработку, написав на <a href="mailto:support@paytoway.ru">support@paytoway.ru</a>.</p>
					<p>- Отписаться от рассылок (через ссылку в письме).</p>
					<hr/>
					<h2>6. Cookies</h2>
					<p>- Сайт использует cookies для анализа трафика и настройки контента.</p>
					<p>- Вы можете отключить cookies в настройках браузера, но это может ограничить функциональность.</p>
					<hr/>
					<h2>7. Ссылки на сторонние сайты</h2>
					<p>Сайт может содержать ссылки на внешние ресурсы. Мы не несем ответственности за их политику конфиденциальности.</p>
					<hr/>
					<h2>8. Изменения в политике</h2>
					<p>Мы оставляем право обновлять данную политику. Актуальная версия всегда доступна на этой странице.</p>
					<hr/>
					<h2>Контакты</h2>
					<p>По вопросам, связанным с конфиденциальностью, обращайтесь написав на <a href="mailto:support@paytoway.ru">support@paytoway.ru</a>.</p>

					<p><b>Дата последнего обновления: 13/01/2025</b></p>
				</Col>
			</Row>
			<Row className="pb-5 d-none d-sm-block">
			</Row>
		</Container>
	</React.Fragment>;
}

export default PrivacyPolicy;