import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap-button-loader';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

class ContactForm extends Component {
	constructor(props){
		super(props);

		// this.inputOrg = React.createRef();
		// this.inputCity = React.createRef();
		// this.inputStreet = React.createRef();
		// this.inputHouse = React.createRef();

		this.state = {
			loading: false
		};

		this.counterStreet = 0;
		this.timeoutStreet = null;
		this.counterHouse = 0;
		this.timeoutHouse = null;

		this.sendForm = this.sendForm.bind(this);
	}

	sendForm() {
		this.setState({loading:!this.state.loading});
		setTimeout(()=>{
			this.setState({loading:!this.state.loading});
		},1000);
	}

	render(){
		return(<Container id="connect">
			<Row className="pt-5 d-none d-sm-block">
			</Row>
			<Row>
				<Col sm={12} md={6}>
					<img src={require('./../images/main.jpg')} style={{width:'100%'}} />
				</Col>
				<Col sm={12} md={6} className="business-r-t">
					<div className="business-r-t-i">
						<b>Получить консультацию</b><br/>
						<p><span>Комплексные решения PayToWay помогут выйти вашему бизнесу на новый уровень. Заполните форму обратной связи и наши менеджеры с вами свяжутся в ближайшее время.<br/>* - Для обращений физ. лиц по вопросам зачисления платежей необходимо обращаться по адресу <a href="mailto:help@paytoway.ru">help@paytoway.ru</a></span></p>
						<p><Form.Control
							placeholder="Ваше имя"
							aria-label="Ваше имя"
							aria-describedby="basic-addon1"
						/></p>
						<p><Form.Control
							placeholder="Телефон"
							aria-label="Телефон"
							aria-describedby="basic-addon1"
						/></p>
						<p><Form.Control
							placeholder="E-Mail"
							aria-label="E-Mail"
							aria-describedby="basic-addon1"
						/></p>
						<div className="mb-3"><FloatingLabel controlId="floatingTextarea2" label="Комментарий">
							<Form.Control
								as="textarea"
								placeholder="При необходимости укажите тему обращения"
								style={{ height: '100px' }}
							/>
						</FloatingLabel></div>
						<p><Button variant="primary" onClick={this.sendForm} loading={this.state.loading}>Отправить</Button></p>
					</div>
				</Col>
			</Row>
			<Row className="pb-5 d-none d-sm-block">
			</Row>
		</Container>);
	}
}

export default ContactForm;