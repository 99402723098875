import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import './../css/whiteLabel.css';

import ContactForm from './../components/contactForm.jsx';

const FeatureSection = ({ title, description, svgComponent, reverse }) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	return (
		<section className={`py-5 ${reverse ? 'bg-light' : ''}`}>
			<Container>
				<Row 
					ref={ref}
					className={`align-items-center ${reverse ? 'flex-row-reverse' : ''}`}
				>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						{svgComponent}
					</Col>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						<h3 className="text-primary mb-4">{title}</h3>
						<p><span>{description}</span></p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

const BrandingIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="brandGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#brandGradient)"
			strokeWidth="2"
			d="M32 10l14 36H18l14-36z"
			strokeDasharray="150"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="150"
				to="0"
				dur="1s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#4dabf7"
			d="M32 28l6 6-6 6-6-6 6-6z"
		>
			<animate
				attributeName="opacity"
				values="0;1;0"
				dur="2s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const IntegrationIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="integrateGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<circle
			cx="32"
			cy="32"
			r="24"
			fill="none"
			stroke="url(#integrateGradient)"
			strokeWidth="2"
			strokeDasharray="200"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="200"
				to="0"
				dur="1.2s"
				fill="freeze"
			/>
		</circle>
		<path
			fill="#f0f8ff"
			stroke="#0d6efd"
			strokeWidth="2"
			d="M32 16l16 16-16 16-16-16 16-16z"
			strokeDasharray="100"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="100"
				to="0"
				dur="1s"
				begin="0.5s"
				fill="freeze"
			/>
		</path>
	</svg>
);

const CostIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="costGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#costGradient)"
			strokeWidth="2"
			d="M10 40l20-28 20 28"
			strokeDasharray="200"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="200"
				to="0"
				dur="1s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#4dabf7"
			d="M24 34h16v8H24z"
		>
			<animate
				attributeName="fill-opacity"
				values="0;1;0"
				dur="2s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const ScaleIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="scaleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#scaleGradient)"
			strokeWidth="2"
			d="M10 32h44M32 10v44"
			strokeDasharray="150"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="150"
				to="0"
				dur="1s"
				fill="freeze"
			/>
		</path>
		<circle
			cx="32"
			cy="32"
			r="8"
			fill="#4dabf7"
		>
			<animate
				attributeName="r"
				values="8;12;8"
				dur="2s"
				repeatCount="indefinite"
			/>
		</circle>
	</svg>
);

const WhiteLabelPage = () => {
	const features = [
		{
			title: 'Полная кастомизация под бренд',
			description: 'Индивидуальный дизайн интерфейса и фирменная символика во всех элементах системы',
			svg: <BrandingIcon />,
		},
		{
			title: 'Быстрая интеграция',
			description: 'Готовое решение с API для подключения к вашей инфраструктуре за 3 дня',
			svg: <IntegrationIcon />,
			reverse: true,
		},
		{
			title: 'Снижение затрат',
			description: 'Отсутствие расходов на разработку и поддержку собственной платежной системы',
			svg: <CostIcon />,
		},
		{
			title: 'Гибкое масштабирование',
			description: 'Автоматическое увеличение мощности системы при росте числа транзакций',
			svg: <ScaleIcon />,
			reverse: true,
		},
	];

	return (
		<Container fluid className="main-container">
			<header className="text-center py-5">
				<h1 className="display-4 text-primary">White-label решение</h1>
				<p className="mt-3"><span>Брендирование платежной системы под ваш бизнес</span></p>
			</header>

			{features.map((feature, index) => (
				<FeatureSection
					key={index}
					title={feature.title}
					description={feature.description}
					svgComponent={feature.svg}
					reverse={index % 2 !== 0}
				/>
			))}
			<ContactForm/>
		</Container>
	);
};

export default WhiteLabelPage;