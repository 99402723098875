import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logoFooter from './../logo_footer.png';

const Footer = () => <footer className="page-footer font-small blue bgcab">
	<div className="container-fluid text-center text-md-left">
		<div className="row">
			<div className="pt-4 col-md-3 mb-md-0 mb-3">
				<h5>Агрегаторам</h5>
				<ul className="list-unstyled">
					<li><a href="/pay_box">Терминалы</a></li>
					<li><a href="/white_label">White Label</a></li>
					<li><a href="/hold_benefits">Холдирование</a></li>
				</ul>
			</div>

			<div className="pt-4 col-md-3 mb-md-0 mb-3">
				<h5>Бизнесу</h5>
				<ul className="list-unstyled">
					<li><a href="/online_payments">Онлайн платежи</a></li>
					<li><a href="/cloud_cash">Облачные кассы</a></li>
					<li><a href="/risk_control">Безопасность</a></li>
				</ul>
			</div>

			<div className="pt-4 col-md-6 mb-md-0 mb-3">
				<h5>Подписаться на рассылку новостей и быть в курсе новых продуктов</h5>
				<p><Form.Control
					placeholder="E-Mail"
					aria-label="E-Mail"
					aria-describedby="basic-addon1"
				/></p>
				<p><Button variant="primary">Подписаться</Button></p>
			</div>

			<hr className="clearfix w-100 d-md-none pb-0"/>

			{/*<div className="col-md-6 mt-md-0 mt-3">*/}
			<div className="col-md-12 mt-md-0 mt-3">
				{/*<h5 className="text-uppercase">Footer Content</h5>*/}
				{/*<p style={{color:'#aaa'}}>Вся представленная информация, носит информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437(2) Гражданского кодекса.</p>*/}
			</div>
		</div>
	</div>

	<Container>
		<Row className="business-r-t py-3">
			<Col sm={12} md={2} className="text-center">
				<a href="/"><img src={logoFooter} style={{width:'100%', maxWidth:'150px'}} alt="logo" /></a>
			</Col>
			<Col sm={12} md={3} className="business-r-t">
				<a href="/privacy_policy">Политика конфиденциальности</a>
			</Col>
			<Col sm={12} md={3} className="business-r-t">
				<a href="/law_info">Правовая информация</a>
			</Col>
			<Col sm={12} md={2} className="business-r-t">
				<a href="https://lk.paytoway.ru">Личный кабинет</a>
			</Col>
			<Col sm={12} md={2} className="business-r-t">
				<a href="mailto:support@paytoway.ru">Поддержка</a>
			</Col>
		</Row>
	</Container>

</footer>

export default Footer