import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import './../css/payBox.css';

import ContactForm from './../components/contactForm.jsx';

const FeatureSection = ({ title, description, svgComponent, reverse }) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	return (
		<section className={`py-5 ${reverse ? 'bg-light' : ''}`}>
			<Container>
				<Row 
					ref={ref}
					className={`align-items-center ${reverse ? 'flex-row-reverse' : ''}`}
				>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						{svgComponent}
					</Col>
					<Col md={6} className={`p-4 ${inView ? 'animate__fadeIn' : 'opacity-0'}`}>
						<h3 className="text-primary mb-4">{title}</h3>
						<p><span>{description}</span></p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

const SpeedIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="speedGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#speedGradient)"
			strokeWidth="2"
			d="M32 10v44M10 32h44"
			strokeDasharray="200"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="200"
				to="0"
				dur="1s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#4dabf7"
			d="M32 28l12 12-4.8 4.8L32 36.8l-7.2 7.2L20 40l12-12z"
		>
			<animate
				attributeName="opacity"
				values="0;1;0"
				dur="2s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const SecurityIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="securityGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#securityGradient)"
			strokeWidth="2"
			d="M32 58.7C16 50 8 38 8 28V8l24-4 24 4v20c0 10-8 22-24 30.7z"
			strokeDasharray="250"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="250"
				to="0"
				dur="1.5s"
				fill="freeze"
			/>
		</path>
		<path
			fill="#4dabf7"
			d="M32 38a6 6 0 100-12 6 6 0 000 12z"
		>
			<animate
				attributeName="fill-opacity"
				values="0;0.8;0"
				dur="2.5s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

const ConvenienceIcon = () => (
	<svg viewBox="0 0 64 64" className="animated-svg">
		<linearGradient id="convenienceGradient" x1="0%" y1="0%" x2="100%" y2="100%">
			<stop offset="0%" stopColor="#0d6efd" />
			<stop offset="100%" stopColor="#4dabf7" />
		</linearGradient>
		
		<path
			fill="none"
			stroke="url(#convenienceGradient)"
			strokeWidth="2"
			d="M48 12H16a4 4 0 00-4 4v32a4 4 0 004 4h32a4 4 0 004-4V16a4 4 0 00-4-4z"
			strokeDasharray="200"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="200"
				to="0"
				dur="1s"
				fill="freeze"
			/>
		</path>
		
		<path
			fill="#fff"
			stroke="#0d6efd"
			strokeWidth="2"
			d="M24 28l8 8 16-16"
			strokeDasharray="50"
		>
			<animate
				attributeName="stroke-dashoffset"
				from="50"
				to="0"
				dur="0.8s"
				begin="0.5s"
				fill="freeze"
			/>
		</path>
	</svg>
);

const PayBoxPage = () => {
	const features = [
		{
			title: 'Мгновенная обработка платежей',
			description: 'Скорость проведения операций до 0.8 секунды благодаря оптимизированным протоколам',
			svg: <SpeedIcon />,
		},
		{
			title: 'Защита данных',
			description: 'Шифрование по стандарту PCI DSS 3.0 и двухфакторная аутентификация',
			svg: <SecurityIcon />,
			reverse: true,
		},
		{
			title: 'Удобство интеграции',
			description: 'Подключение к любой учетной системе через API за 24 часа',
			svg: <ConvenienceIcon />,
		},
	];

	return (
		<Container fluid className="main-container">
			<header className="text-center py-5">
				<h1 className="display-4 text-primary">Сеть партнеров платежных терминалов</h1>
				<p className="mt-3"><span>Большая сеть партеров для офлайн приема платежей</span></p>
			</header>

			{features.map((feature, index) => (
				<FeatureSection
					key={index}
					title={feature.title}
					description={feature.description}
					svgComponent={feature.svg}
					reverse={index % 2 !== 0}
				/>
			))}
			<ContactForm/>
		</Container>
	);
};

export default PayBoxPage;